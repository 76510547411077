.highlighted-categories {
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        flex-flow: row wrap;
        border-bottom: 1px solid rgba($secondary, 0.5);

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__icon {
        max-width: spacer(10);
        max-height: spacer(10);
        object-fit: contain;
        height: auto;
        margin: auto;
        transition: all $global-duration;
    }

    &__item {
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: spacer(10) spacer(4);
        cursor: pointer;
        position: relative;
        z-index: 1;
        transition: color $global-duration;
        border-bottom: 1px solid rgba($secondary, 0.5);
        word-break: break-word;
        text-align: center;
        border-right: 1px solid rgba($secondary, 0.5);

        @include media-breakpoint-down(lg) {
            &:nth-of-type(even) {
                border-right: none;
            }
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
            border-bottom: none;
        }

        &:before {
            content: ' ';
            background: $gradient-vertical;
            position: absolute;
            inset: 0;
            z-index: -1;
            opacity: 0;
            transition: opacity $global-duration;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 20%;
            min-width: 20%;
            border-right: 1px solid rgba($secondary, 0.5);

            &:nth-child(5n) {
                border-right: none;
            }

            &:nth-last-child(-n+5) {
                border-bottom: none;
            }

            &:hover {
                color: $white;

                &:before {
                    opacity: 1;
                }

                .highlighted-categories__icon {
                    filter: grayscale(1) invert(1) brightness(1.5);
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:114";