// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Panes

@use "sass:math";

$pane-spacer: spacer(10); // 40px
$pane-spacers: (
    xs: $pane-spacer,
    lg: #{$pane-spacer * 2},
);

// Color system

// scss-docs-start color-variables
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #fff;
$black: #000;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$dark: #022944;
$color-base: #292929;
$primary: #0F4162;
$secondary: #2AC298;
$tertiary: #F5B637;
$quaternary: #C24E2B;
$quinary: #F57936;
$septenary: #CCDBFF;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: #F1F4F6;

$gradient: linear-gradient(to right, #197596, #2AC498);
$gradient-vertical: linear-gradient(to bottom, #197596, #2AC498);
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
    "base": $color-base,
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "quaternary": $quaternary,
    "quinary": $quinary,
    "septenary": $septenary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "white": $white,
);
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// scss-docs-end theme-colors-rgb

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29"));

// Prefix for :root CSS variables

$variable-prefix: dw-;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0)) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
    // 0: 0,
    // 1: $spacer * 0.25,
    // 2: $spacer * 0.5,
    // 3: $spacer * 0.75,
    // 4: $spacer * 1,
    // 5: $spacer * 1.25,
    // 6: $spacer * 1.5,
    // 7: $spacer * 1.75,
    // 8: $spacer * 2,
);

@for $i from 0 through 50 {
    $spacers: map-merge($spacers, ($i: #{($spacer * 0.25) * $i} ));
}

$enable-negative-margins: true;

// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
    0: 0,
    50: 50%,
    100: 100%,
);
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $color-base;
$body-text-align: null;

// Links
//
// Style anchor elements.

$link-color: $secondary;
$link-decoration: underline;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: null;

$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px,
//     xxl: 1400px,
//     xxxl: 1680px,
//     xxxxl: 1920px,
// );

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1280px,
    xxl: 1680px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
// $container-max-widths: (
//     xs: 100%,
//     sm: 540px,
//     md: 720px,
//     lg: 960px,
//     xl: 1060px,
//     xxl: 1220px,
//     xxxl: 1580px,
//     xxxxl: 1800px,
// );

$container-max-widths: (
    sm: 580px,
    md: 760px,
    lg: 1000px,
    xl: 1260px,
    xxl: 1480px,
);

// scss-docs-end container-max-widths

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 2.5rem;
$grid-row-columns: 6;

$gutters: $spacers;

// Container padding

// $container-padding-x: $grid-gutter-width * 0.5;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px;
$border-widths: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
);

$border-color: rgba($color-base, 0.1);
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);
// scss-docs-end box-shadow-variables

$component-active-color: $white;
$component-active-bg: $primary;

// scss-docs-start caret-variables
$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;
// scss-docs-end caret-variables

$global-duration: 0.3s;
$transition-base: all $global-duration ease-in-out;
$transition-fade: opacity $global-duration linear;
// scss-docs-start collapse-transition
$transition-collapse: height $global-duration ease;
$transition-collapse-width: width $global-duration ease;

// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
);
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Poppins", sans-serif;
// stylelint-enable value-keyword-case
$font-family-base: $font-family-sans-serif;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.75;
$h5-font-size: $font-size-base * 1.375;
$h6-font-size: $font-size-base * 1.125;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5;
$headings-font-family: $font-family-sans-serif;
$headings-font-style: null;
$headings-font-weight: 500;
$headings-line-height: 1.5;
$headings-color: null;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
    1: 5rem,
    2: 4.5rem,
    3: 4rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem,
);

$display-font-weight: 300;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.875em;

$sub-sup-font-size: 0.75em;

$text-muted: rgba($color-base, 0.25);

$initialism-font-size: $small-font-size;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
// scss-docs-end zindex-stack

// Inputs

$input-bg: #f0f4ff;


// Header
$header-height: spacer(25);

;@import "sass-embedded-legacy-load-done:2";