.usps {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: spacer(4) spacer(20);
    margin-top: spacer(10);

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__item {
        display: flex;
        align-items: flex-start;
        gap: spacer(3);
        
    }
}

;@import "sass-embedded-legacy-load-done:115";