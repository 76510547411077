$deco-height: px-to-rem(128);

.quicklink {
    padding: spacer(9);
    border-radius: #{$border-radius * 4};
    display: flex;
    flex-direction: column;
    gap: spacer(10);
    color: $white;
    position: relative;
    height: 100%;
    justify-content: space-between;
    z-index: 1;
    
    &:nth-of-type(2),
    &:nth-of-type(3) {
        @include media-breakpoint-up(lg) {
            &:after {
                content: ' ';
                display: block;
                aspect-ratio: 216 / 128;
                height: $deco-height;
                mask-image: url('../../assets/app/img/quicklinks-deco.svg');
                position: absolute;
                z-index: -1;
            }
        }
    }

    &:nth-of-type(2) {
        &:after {
            right: 0;
            top: spacer(14);
            transform: translateY(-100%) scale(-1);
        }
    }

    &:nth-of-type(3) {
        &:after {
            left: 0;
            bottom: spacer(14);
            transform: translateY(100%);
        }
    }

    &__icon {
        width: spacer(12);
        height: spacer(12);
        border-radius: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $white;
        background-color: rgba($white, 0.25);
        gap: spacer(8);
        overflow: hidden;

        .icon {
            flex-shrink: 0;
            transition: none;
            transform: translateX(-#{spacer(4)});
        }
    }

    @each $name, $color in $theme-colors {
        &--#{$name} {
            background-color: $color;

            &:after {
                background-color: $color;
            }

            @if $name == 'tertiary' or $name == 'quinary' {
                .quicklink__icon {
                    background-color: rgba($black, 0.1);
                }
            }
        }
    }

    &:hover {
        .quicklink__icon .icon {
            transform: translateX(#{spacer(8)});
            transition: transform $global-duration;
        }
    }

    &s-container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: #{$grid-gutter-width * 0.5};

        @include media-breakpoint-up(lg) {
            gap: $grid-gutter-width;
            padding: spacer(4) 0;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.quicklink-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;

    &__text,
    &__icon {
        transition: all $global-duration;
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: inherit;
        padding: spacer(6) 0;
        border-bottom: 1px solid rgba($black, 0.1);

        &:hover {
            .quicklink-list__text,
            .quicklink-list__icon {
                transform: translateX(spacer(2));
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:113";