.nav--footer-menu {
    flex-direction: column;

    .nav__link {
        padding-left: 0;
        padding-right: 0;
        color: $white;
    }
}

.nav--meta-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacer(3);

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: flex-end;
        gap: spacer(12);
    }
    
    .nav__link {
        padding: 0;
        color: rgba($white, 0.8);

        &:hover {
            color: $white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:129";