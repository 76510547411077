.pagination {
    align-items: center;
    justify-content: center;
    gap: spacer(6);

    .page-number {
        color: rgba($primary, 0.5);
        text-decoration: none;
        padding: spacer(2);
    }

    @include media-breakpoint-down(lg) {
        li:not(.prev, .next) {
            display: none;
        }
    }

    .current {
        color: $white;
        background-color: $secondary;
        height: spacer(8);
        width: spacer(8);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
    }

    .btn:disabled,
    .btn.disabled {
        opacity: 0.5;
    }

    .dots {
        font-size: 0;

        &:before {
            content: '...';
            font-size: $font-size-base;
        }
    }
}

;@import "sass-embedded-legacy-load-done:130";