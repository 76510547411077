.o-marker {
    &--signbedrijf {
        color: $quaternary;
    }

    &__image {
        width: 100%;
        height: px-to-rem(100);
        margin: 0 0 spacer(4) 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__title {
        &--signbedrijf {
            color: $quaternary;
        }
    }
}

.pane--googleMaps {
    iframe {
        width: 100%;
    }

    .c-maps-content__text {
        overflow: auto;
        max-height: px-to-rem(450);
        padding: spacer(4) 0;
    }
}

;@import "sass-embedded-legacy-load-done:117";