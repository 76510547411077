.has-border-radius {
    border-radius: spacer(2);

    @include media-breakpoint-up(lg) {
        border-radius: spacer(8);
    }

    .pane__background--color {
        border-radius: spacer(2);

        @include media-breakpoint-up(lg) {
            border-radius: spacer(8);
        }
    }
}

;@import "sass-embedded-legacy-load-done:125";