.video {
    aspect-ratio: 16 / 9;
    background-size: cover;
    border-radius: spacer(2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    @include media-breakpoint-up(lg) {
        border-radius: spacer(8);
    }

    &__button {
        display: flex;
        width: spacer(15);
        height: spacer(15);
        border-radius: 100%;
        border: 2px solid $white;
        justify-content: center;
        align-items: center;
        color: $white;
        transition: all $global-duration;

        &:hover {
            background-color: $white;
            color: $secondary;
        }

        @include media-breakpoint-up(lg) {
            width: spacer(22);
            height: spacer(22);
        }
    }
}

;@import "sass-embedded-legacy-load-done:105";