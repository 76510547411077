.nav {
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-wrap: wrap;
        justify-content: space-between;
        transition: color $global-duration;
    }

    &__link {
        padding: spacer(2) spacer(4);
        text-decoration: none;
    }
}

;@import "sass-embedded-legacy-load-done:126";