%tabs-active {
    background-color: $secondary;

    .tabs__icon {
        opacity: 1;
    }
}

.tabs {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-direction: column;
    gap: spacer(3);
    grid-column: span 12;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        grid-column: span 6;
    }
    
    &__item {
        padding: spacer(6);
        color: $color-base;
        border-radius: #{$border-radius * 3};
        font-weight: $font-weight-medium;
        letter-spacing: 0.5px;
        cursor: pointer;
        transition: background-color $global-duration;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: spacer(3);

        &:hover,
        &.is-active {
            @extend %tabs-active;
        }
    }

    &__icon {
        opacity: 0;
        transition: opacity $global-duration;
    }

    &__content {
        transform: translateY(spacer(3));
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity $global-duration ease-in, transform $global-duration ease-in;

        &.is-active {
            height: auto;
            opacity: 1;
            transform: translateY(0);
        }
    }

    &-container {
        grid-column: span 12;

        @include media-breakpoint-up(lg) {
            grid-column: span 5 / 13;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: $grid-gutter-width;
        padding: spacer(5);

        @include media-breakpoint-up(lg) {
            padding: spacer(10);
            display: grid;
            grid-template-columns: repeat(12, calc((100% - #{$grid-gutter-width * 11}) / 12));
        }

        @include media-breakpoint-up(xl) {
            padding: spacer(16);
        }
    }
}

;@import "sass-embedded-legacy-load-done:116";