.pill {
    border-radius: $border-radius-pill;
    padding: spacer(2) spacer(8);
    font-weight: $font-weight-medium;
    font-size: px-to-rem(18);
    letter-spacing: 0.56px;
    transition: background-color $global-duration;

    @each $name, $color in $theme-colors {
        &--#{$name} {
            background-color: $color;

            &[href]:hover {
                background-color: darken($color, 10%);
            }
        }
    }

    &--single {
        text-transform: capitalize
    }

    &--project {
        @extend .pill--secondary;
        @extend .pill--single;

        color: $white;
        text-decoration: none;
    }

    &--news {
        @extend .pill--tertiary;
        @extend .pill--single;

        color: $white;
        text-decoration: none;
    }

    &-container {
        margin-top: spacer(10);
        display: flex;
        gap: spacer(4);
        justify-content: center;
    }
}

;@import "sass-embedded-legacy-load-done:106";