.card {
    position: relative;
    display: flex;
    flex-direction: column;

    &__title {
        word-wrap: break-word;
    }

    &__image {
        aspect-ratio: 16 / 9;
        border-radius: #{$border-radius * 3};
    }

    &--horizontal {
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &--bordered {
        border: 1px solid rgba($color-base, 0.1);
    }

    &--article {
        gap: spacer(5);

        .card__title {
            font-size: px-to-rem(20);
            letter-spacing: 0.5px;
            font-weight: $font-weight-bold;
        }

        .card__category {
            opacity: 0.7;
            font-size: px-to-rem(22);
            margin-bottom: spacer(1);
            margin-top: spacer(4);
        }

        .card__header {
            border-radius: 0;
            overflow: hidden;
            position: relative;
        }

        .card__image {
            border-radius: 0;
            transition: transform #{$global-duration * 2};
            aspect-ratio: 4 / 4;
        }

        &:hover {
            .card__image {
                transform: scale(1.1);
            }
        }

        .card__arrow {
            color: $color-base;
            display: flex;
            padding: spacer(4);
            background: $white;
            position: absolute;
            bottom: spacer(5);
            right: spacer(5);
            border-radius: 100%;
        }
    }
}

.pane--archive {
    .card--article {
        .card__image {
            aspect-ratio: 4 / 4;
        }
    }
}

;@import "sass-embedded-legacy-load-done:112";