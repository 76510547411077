.downloads--title {
  font-size: px-to-rem(18);
  font-weight: $font-weight-normal;
  display: flex;
  gap: spacer(3);
  align-items: center;
  margin: 0;
}

.info-bar {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding: spacer(3) spacer(5);
  background-color: $dark;
  color: $white;
  border-radius: 4px;
  align-items: center;

  &--transparent {
    background-color: transparent;
    color: $dark;
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.downloads-block {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: spacer(3);
  padding: spacer(10) spacer(5);
  align-items: center;
}

.downloads__inner {
  border-radius: 12px;
  padding: spacer(7) spacer(5);
  margin-bottom: spacer(12);
}

.download__item-title, .download__item-size {
  font-size: px-to-rem(14);
}


.downloads-block--odd {
  background-color: #F3FCFF;
}

.downloads-block--even {
  background-color: $white;
}

.downloads--icon {
  width: 3rem;
  height: 3rem;
  display: block;
  border-radius: 6px;
}

.docs-title {
  font-weight: $font-weight-medium;
}

.docs-title--right {
  justify-self: center;
}

.downloads-count {
  justify-self: center;
}

.download__item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
}

.download__item-size {
  justify-self: center;
}

.download__item-link {
  justify-self: end;
}
;@import "sass-embedded-legacy-load-done:107";